@use '@/styles/utils/mixins.scss' as *;

.featureSliderContainer {
  position: relative;
  margin: 56px 0;

  &.vertical {
    margin: 80px 0;
  }
  @media screen and (max-width: $minTablet) {
    margin: 40px 0;
    &.vertical {
      margin: 40px 0;
    }
  }

  .blockContainer {
    padding: 0 !important;
  }
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @media screen and (max-width: $minTablet) {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
        @media screen and (max-width: $minTablet) {
          font-size: 24px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
        @media screen and (max-width: $minTablet) {
          font-size: 16px;
          line-height: 1;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  .navigationControl {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
    @media screen and (max-width: $minTablet) {
      justify-content: flex-end;
      margin: 0 24px;
      margin-bottom: 16px;
    }
  }
  .navigation {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .buttonPrev,
  .buttonNext {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    svg {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: $minTablet) {
      width: 48px;
      height: 48px;
    }
    &:hover,
    &:focus,
    &:active {
      opacity: 0.9;
    }
  }
  .pagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    left: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    font-size: 12px;
    @media screen and (max-width: $minTablet) {
      margin-right: 4px;
    }
  }

  .descriptionViewport {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    overflow-y: visible;
    @media screen and (max-width: $minTablet) {
      overflow: visible;
      padding: 0 24px;
    }
    .descriptionContainer {
      width: max-content;
      display: flex;
      @media screen and (max-width: $minTablet) {
        position: relative;
        display: grid;
        margin: 0;
        padding: 0;
        overflow: hidden;
        width: 100%;
      }
      li:first-child {
        padding: 0 0 0 340px;
        flex-basis: calc(410px + 292px);
        @media screen and (max-width: $maxWideScreenDesktop) {
          padding: 0 0 0 60px;
          flex-basis: calc(410px + 60px);
        }
        @media screen and (max-width: $minWideScreenDesktop) {
          padding: 0 0 0 24px;
          flex-basis: calc(410px + 24px);
        }
        @media screen and (max-width: $minTablet) {
          padding: 0 0 0 0;
          flex-basis: none;
        }
      }
      li:last-child {
        padding: 0 340px 0 48px;
        flex-basis: calc(410px + 340px);
        @media screen and (max-width: $maxWideScreenDesktop) {
          padding: 0 24px 0 48px;
          flex-basis: calc(410px + 60px);
        }
        @media screen and (max-width: $minWideScreenDesktop) {
          padding: 0 24px 0 48px;
          flex-basis: calc(410px + 24px);
        }
        @media screen and (max-width: $minTablet) {
          padding: 0 0 0 0;
          flex-basis: none;
        }
      }
    }

    .descriptionBlock {
      padding: 0 0 0 48px;
      flex-basis: 410px;
      cursor: pointer;
      display: flex;
      min-height: 100px;
      color: $disabledButtonText;

      &.active {
        color: $kiaMidnightBlack;
      }

      .featureBar {
        background: currentColor;
        height: 84px;
        width: 2px;
        flex: 0 0 auto;
        @media screen and (max-width: $minTablet) {
          display: none;
        }
      }
      .headingElement {
        padding: 0 0 0 24px;
        @media screen and (max-width: $minTablet) {
          padding: 0 0 0 0px;
        }
      }
      @media screen and (max-width: $minTablet) {
        padding: 0;
        flex-basis: 100%;
        opacity: 0;
        transition: opacity 1s;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        &.active {
          opacity: 1;
          display: block;
        }
      }
    }

    &.vertical {
      width: 100%;
      height: auto;
      max-height: 476px;
      overflow-x: visible;
      overflow-y: hidden;
      display: flex;
      @media screen and (max-width: $minWideScreenDesktop) {
        max-height: 360px;
      }

      @media screen and (max-width: $minTablet) {
        overflow: visible;
        padding: 0;
        max-height: 100%;
        display: block;
        .headingElement {
          padding: 0 24px;
        }
      }

      .descriptionContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        @media screen and (max-width: $minTablet) {
          position: relative;
          display: grid;
          margin: 0;
          padding: 0;
          overflow: hidden;
          width: 100%;
        }
        li:first-child {
          padding: 0;
          flex-basis: 100%;
          @media screen and (max-width: $maxWideScreenDesktop) {
            padding: 0;
            flex-basis: 100%;
          }
          @include tab() {
            padding: 0;
            flex-basis: 100%;
          }
          @media screen and (max-width: $minTablet) {
            padding: 0 0 0 0;
            flex-basis: none;
          }
        }
        li:last-child {
          padding: 0;
          flex-basis: 100%;
          @media screen and (max-width: $maxWideScreenDesktop) {
            padding: 0;
            flex-basis: 100%;
          }
          @include tab() {
            padding: 0;
            flex-basis: 100%;
          }
          @media screen and (max-width: $minTablet) {
            padding: 0 0 0 0;
            flex-basis: none;
          }
        }
      }
      .descriptionBlock {
        padding: 0;
        flex-basis: 100%;
        min-height: auto;
        @media screen and (max-width: $minTablet) {
          padding: 0;
          flex-basis: 100%;
          opacity: 0;
          transition: opacity 1s;
          grid-column: 1;
          grid-row: 1;
          width: 100%;
          &.active {
            opacity: 1;
            display: block;
          }
        }
      }
    }
  }

  .verticalContainer {
    @media screen and (max-width: $minTablet) {
      flex-direction: column;
      padding: 0;
    }
    .navigationControl {
      justify-content: flex-start;
      @media screen and (max-width: $minTablet) {
        justify-content: flex-end;
        margin: 0 24px;
        margin-bottom: 16px;
      }
    }
    .navigation {
      flex-direction: column;
      @media screen and (max-width: $minTablet) {
        flex-direction: row;
      }
    }
    .featureBlock {
      padding-left: 80px;
      padding-right: 90px;
      width: 100%;
      max-width: 640px;
      @media screen and (max-width: $minWideScreenDesktop) {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 500px;
      }
      @media screen and (max-width: $minTablet) {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
      }
    }
    .buttonPrev,
    .buttonNext {
      transform: rotate(90deg);
      @media screen and (max-width: $minTablet) {
        transform: rotate(0deg);
      }
    }

    .showcaseWindow,
    .showcaseContainer,
    .showcaseBlock,
    .featureImage {
      height: 100%;
      max-height: 100%;
    }
  }
  .featureDescription {
    font-size: 16px;
    @media screen and (max-width: $minTablet) {
      font-size: 14px;
    }
  }
  .showcaseContainer {
    position: relative;
    display: grid;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .showcaseBlock {
    opacity: 0;
    transition: opacity 1s;
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    &.active {
      opacity: 1;
      display: block;
    }
  }

  .featureImage {
    object-fit: cover;
    object-position: center;
    max-height: 400px;
    height: auto;
    width: 100%;
  }

  .desktopShow {
    display: none;
    @media (min-width: 769px) {
      display: block;
    }
  }
  .mobileShow {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
}
